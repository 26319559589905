import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import type { Player } from './lib/models/player.type';
import type { MapData, MapNode } from './lib/models/map.type';
import { memory } from './lib/utils/memory';
import type { Technique } from './lib/models/techniques.type';
memory.init();

export const version = '0.0.27';
// export const state = writable('menu');
export const screens = Screens();
export const stateNode: Writable<MapNode> = writable();

export const options = writable({
  tooltipsKeywords: memory.get('tooltipsKeywords') === false ? false : true,
  autoPlay: memory.get('autoPlay') || false,
  invincible: memory.get('invincible') || false,
});

export const playerTruth : Writable<Player> = writable();
export const activePlayer : Writable<Player> = writable();
export const inactivePlayer : Writable<Player> = writable();

export const networkMap = writable<any>();
export const map = writable<MapData>();

export const playerInstance = history({});
export const enemyInstance = history({});
export const dieHistory = history([]);
export const actionHistory: Array<string> = [];
export const techniqueHistory: Array<any> = [];
export let lockedDie = [];
export const intervals = {
  particleInterval: null
};
export let glossary = {
  open: writable(false),
  selected: writable('techniques'),
  searchTerm: writable(''),
  searchDie: writable([])
};

export function getStore(store) {
  let $val
  store.subscribe($ => $val = $)()
  return $val
}

function history(initialState) {
  const current = writable(initialState); // The only store really needed
  const history = [initialState];
  let index = 0;

  const refresh = () => {
    current.set(history[index]);
  };

  const set = (newState) => {
    history[index] = newState;
    refresh();
  }

  const put = (newState) => {
    history.length = index+1;

    // TODO automate this to find and duplicate objects by reference
    if (newState.statusEffects) {
      newState.statusEffects = newState.statusEffects.map(mod => {
        return mod.clone(newState);
      });
    }
    if (newState.inventory) {
      newState.inventory = newState.inventory.map(mod => {
        return mod.clone(newState);
      });
    }
    if (newState.traits) {
      newState.traits = newState.traits.map(mod => {
        return mod.clone(newState);
      });
    }

    history.push(newState);
    index++;
    refresh();
    return history[index];
  }

  const clear = () => {
    history[0] = history[index];
    history.length = 1;
    index = 0;
    refresh();
  }

  const undo = () => {
    index = Math.max(0, index-1);
    refresh();
  }

  return {
    set,
    put,
    history,
    subscribe : current.subscribe,
    undo: undo,
    redo: () => { index = Math.min(history.length-1, index+1); refresh() },
    clear: () => { clear() },
    count: () => { return index }
  };
}

function Screens(){
  const current = writable([{
    type: 'menu',
    state: 'visible',
    data: {}
  }]);

  const self = {
    subscribe: current.subscribe,
    push: (screen: string, data?: any) => {
      current.update(screens => {
        screens.forEach(screen => {
          if (screen.state == 'visible' || screen.state == 'loading') {
            screen.state = 'hidden';
          }
        });
        screens.push({
          type: screen,
          state: 'loading',
          data: data || {}
        });
        return screens;
      });
    },
    set: (screen: string, data?: any) => {
      current.update(screens => {
        // pop
        let screeen = screens.findLast(screen => screen.state == 'visible');
        screeen.state = 'unloading';
        setTimeout(() => {
          screeen.state = 'unloaded';
        }, 1000);

        // push
        screens.forEach(screen => {
          if (screen.state == 'visible' || screen.state == 'loading') {
            screen.state = 'hidden';
          }
        });
        screens.push({
          type: screen,
          state: 'loading',
          data: data || {}
        });
        return screens;
      });
    },
    pop: () => {
      current.update(screens => {
        let screen = screens.findLast(screen => screen.state == 'visible');
        screen.state = 'unloading';
        setTimeout(() => {
          screen.state = 'unloaded';
        }, 1000);
        return screens;
      });
    },
    removeUnloaded: () => {
      current.update(screens => {
        screens = screens.filter(screen => screen.state !== 'unloaded');
        return screens;
      });
    },
    clear: () => {
      current.set([{
        type: 'menu',
        state: 'visible',
        data: {}
      }]);
    }
  }
  return self;
}