<script lang='ts'>
  import { lockedDie } from '../../store';
  import type { DieFace } from '../models/face.type';
  import DieTicks from './die-ticks.svelte';
  import DieIcon from './die-icon.svelte';

  let clazz = '';
  export { clazz as class };
  export let dieType = '';
  export let colour = 'blank';
  export let solid = false;
  export let sizeMod = 0;
  export let level = 0;

  let element;
  let dieRender: Array<DieFace>;
  if(Array.isArray(dieType)){
    dieRender = dieType;
  }
  else{
    dieRender = [dieType as DieFace];
  }

  function setLockedDie(){
    //@ts-ignore
    // lockedDie = lockedDie;
  }

  /* setTimeout(()=>{
    if(element){
      element.addEventListener('mouseover', ()=>{
        lockedDie.forEach(die => {
          if(die.value() == dieType){
            die.selected = true;
          }
        });
        setLockedDie();
      });

      element.addEventListener('mouseleave', ()=>{
        lockedDie.forEach(die => {
          die.selected = false;
        });
      });
    }
  }, 100); */

  $: if (dieType) {
    if(Array.isArray(dieType)){
      dieRender = dieType;
    }
    else{
      dieRender = [dieType as DieFace];
    }
  }
</script>

<div
  class='
    die
    {clazz || ""}
    {colour}
    size-{sizeMod}
  '
  bind:this={element}
>
  <div class='die-2d face {solid ? "solid" : "hollow"}'>
    <DieTicks level={level}></DieTicks>
    <DieIcon face={dieRender} sizeMod={sizeMod}/>
  </div>

  <div class='slot'>
    <slot></slot>
  </div>
</div>

<style lang='less'>
  .die{
    width: calc(var(--dice-size) + var(--dice-gap));
    height: calc(var(--dice-size) + var(--dice-gap));
    border-radius: 4px;
    transition: opacity 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.size-2{
      width: calc(var(--dice-size) / 2);
      height: calc(var(--dice-size) / 2);
    }
    &.size-1{
      width: calc(var(--dice-size) / 1.5);
      height: calc(var(--dice-size) / 1.5);
    }

    .die-2d{
      min-width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-image: url('../../assets/die.png');
      // background-size: cover;
      // background-position: center;
      border-radius: 4px;
      position: relative;
    }

    .face{
      // border: 1px solid black;
      &.hollow{
        background-color: transparent;
        box-shadow: inset 3px 3px 5px 0 var(--background-colour-dark), inset -3px -3px 5px var(--background-colour-light);
      }
      // &.solid{
        // background-color: unset;
        // box-shadow: inset 3px 3px 5px 0 var(--background-colour-light), inset -3px -3px 5px var(--background-colour-dark);
      // }
    }

    &.hidden{
      opacity: 0;
      pointer-events: none;
    }
  }

  .slot{
    position: absolute;
    opacity: 0.75;
    z-index: 1;
  }
</style>
