import { actionHistory } from '../../store';

export function setupEnvironment(type) {
  const environment = environments.find(env => env.id == type);

  const root: HTMLElement = document.querySelector(':root');
  root.style.setProperty('--background-colour', environment.colour);
  root.style.setProperty('--background-colour-light', environment.colourLight);
  root.style.setProperty('--background-colour-dark', environment.colourDark);

  root.style.setProperty('--background-image', `url('./src/assets/backgrounds/${environment.background}')`);
}

export enum EnvironmentType {
  Cave = 'cave',
  Coast = 'coast',
  Desert = 'desert',
  Forest = 'forest',
  Lake = 'lake',
  Mountain = 'mountain',
  Plains = 'plains',
  TownAlley = 'town-alley',
  TownArena = 'town-arena',
  TownBarracks = 'town-barracks',

  River = 'river',
  Swamp = 'swamp',
  ElementalNexus = 'elemental-nexus',
  CorestoneChamber = 'corestone-chamber',
  TwilightGarden = 'twilight-garden',
  AetherialPlateau = 'aetherial-plateau',

  Default = 'default',
}

export type EnvironmentDefinition = {
  id: EnvironmentType;
  title: string;
  text: string;
  background: string;
  colour: `#${string}`;
  colourLight: `#${string}`;
  colourDark: `#${string}`;
}

export const environments: Array<EnvironmentDefinition> = [
  {
    id: EnvironmentType.Cave,
    title: 'Cave',
    text: 'A dimly lit underground cavern echoing with the sounds of dripping water and mysterious echoes.',
    background: 'cave',
    colour: '#381708',
    colourLight: '#65290e',
    colourDark: '#220e05',
  }, {
    id: EnvironmentType.Coast,
    title: 'Coast',
    text: 'Sandy shores battered by crashing waves, with seagulls circling overhead and the salty breeze in the air.',
    background: 'coast',
    colour: '#18434d',
    colourLight: '#276675',
    colourDark: '#113139',
  }, {
    id: EnvironmentType.Desert,
    title: 'Desert',
    text: 'A vast, arid landscape with swirling sands, scorching sun, and occasional oases offering respite.',
    background: 'desert',
    colour: '#D2B48C',
    colourLight: '#e6c8a0',
    colourDark: '#bea078',
  }, {
    id: EnvironmentType.Forest,
    title: 'Forest',
    text: 'Towering trees, dappled sunlight, and an undergrowth teeming with wildlife and hidden dangers.',
    background: 'forest',
    colour: '#232510', //#228B22
    colourLight: '#666222',
    colourDark: '#131409',
  }, {
    id: EnvironmentType.Lake,
    title: 'Lake',
    text: 'A serene expanse of water framed by serene surroundings, rippling with gentle waves and potential hazards lurking beneath.',
    background: 'lake',
    colour: '#265f52',
    colourLight: '#3a7366',
    colourDark: '#124b3e',
  }, {
    id: EnvironmentType.Mountain,
    title: 'Mountain',
    text: 'Rugged peaks piercing the sky, chilly winds, and treacherous cliffs offering both peril and grandeur.',
    background: 'mountain',
    colour: '#5d482a',
    colourLight: '#715c3e',
    colourDark: '#493416',
  }, {
    id: EnvironmentType.Plains,
    title: 'Plains',
    text: 'Endless grasslands stretching to the horizon, whispering winds, and scattered trees dotting the landscape.',
    background: 'plains',
    colour: '#aab350',
    colourLight: '#bec764',
    colourDark: '#969f3c',
  }, {
    id: EnvironmentType.TownAlley,
    title: 'Alley',
    text: 'Narrow passages between buildings, dimly lit by flickering lanterns, filled with hidden nooks and potential ambush spots.',
    background: 'town-alley',
    colour: '#2c2c2c',
    colourLight: '#404040',
    colourDark: '#181818',
  }, {
    id: EnvironmentType.TownArena,
    title: 'Arena',
    text: 'A bustling and open area, typically used for gatherings or competitions, surrounded by cheering crowds and grandstands.',
    background: 'town-arena',
    colour: '#423f47', //#CD853F
    colourLight: '#64606c',
    colourDark: '#333037',
  }, {
    id: EnvironmentType.TownBarracks,
    title: 'Barracks',
    text: 'A structured compound filled with military paraphernalia and training areas, typically regimented and guarded.',
    background: 'town-barracks',
    colour: '#8B4513',
    colourLight: '#9f5927',
    colourDark: '#773100',
  }, {
    id: EnvironmentType.Swamp,
    title: 'Swamp',
    text: 'Murky waters, tangled roots, and foggy pathways, with eerie sounds and hazardous terrain challenging every step.',
    background: 'swamp',
    colour: '#433626',//556B2F
    colourLight: '#a8945d',
    colourDark: '#261e17',
  }, {
    id: EnvironmentType.River,
    title: 'River',
    text: 'A winding river coursing through the landscape, bordered by lush greenery and offering a tranquil yet potentially perilous passage.',
    background: 'river',
    colour: '#433626',//556B2F
    colourLight: '#a8945d',
    colourDark: '#261e17',
  },

  {
    id: EnvironmentType.ElementalNexus,
    title: 'Elemental Nexus',
    text: 'A mystical area near the Elemental Peaks, crackling with elemental energy, featuring floating platforms amidst shifting landscapes representing the different elements.',
    background: 'elemental-nexus',
    colour: '#FFA500',
    colourLight: '#ffb914',
    colourDark: '#eb9100',
  }, {
    id: EnvironmentType.CorestoneChamber,
    title: 'Corestone Chamber',
    text: 'Hidden sanctuaries deep within the mountains, where Corestones manifest, pulsating with raw elemental power, each chamber reflecting a specific element.',
    background: 'corestone-chamber',
    colour: '#FFD700',
    colourLight: '#ffeb14',
    colourDark: '#ebc300',
  }, /* {
    id: EnvironmentType.TwilightGarden,
    title: 'Twilight Garden',
    text: 'A mystical garden shrouded in perpetual twilight, where strange and magical plants bloom, and creatures of twilight wander among bioluminescent foliage.',
    background: 'twilight-garden',
    colour: '#9370DB',
    colourLight: '#a784ef',
    colourDark: '#7f5cc7',
  }, {
    id: EnvironmentType.AetherialPlateau,
    title: 'Aetherial Plateau',
    text: 'A floating landmass suspended high above the ground, accessible through mysterious portals or hidden pathways, filled with ethereal creatures and unique celestial flora.',
    background: 'aetherial-plateau',
    colour: '#ebebeb',
    colourLight: '#FFFFFF',
    colourDark: '#d7d7d7',
  }, */


  {
    id: EnvironmentType.Default,
    title: 'Default',
    text: '',
    background: 'town-arena',
    colour: '#121212',
    colourLight: '#2c2c2c',
    colourDark: '#050505',
  }
];

setupEnvironment('default');

/* function lightenDarkenColor(col, amt) {
  let usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

let color = "2E75A3";
let lighterColor = lightenDarkenColor(color, 20); // Lighten by 20
let darkerColor = lightenDarkenColor(color, -20); // Darken by 20

console.log(lighterColor); // Lighter color
console.log(darkerColor); // Darker color */