
<script lang='ts'>
  import { alertData } from './modals';

  let onConfirm = null;

  alertData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    alertData.set(null);
  }
</script>

{#if $alertData}
<div class='modal-container'>
  <div class='modal'>
    {#if $alertData.title}
      <div class='header'>
        <h1>{$alertData.title}</h1>
      </div>
    {/if}

    {#if $alertData.text}
      <div class='body'>
        <p>{$alertData.text}</p>
      </div>
    {/if}

    <div class='functions'>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang='less'>
  :global(.modal-container) {
    position: absolute;
    z-index: 1111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.25);
    color: var(--light-font);

    :global(.modal){
      width: fit-content;
      background: var(--background-colour);
      color: var(--light-font);
      margin: auto;
      margin-top: 100px;
      padding: 20px;
      border-radius: 8px;
      border-color: var(--background-colour-dark);
      border-width: 2px;
      border-style: solid;

      :global(.header){
        display: flex;
        align-items: center;
        justify-content: space-between;
/*        padding: 0 20px;*/

        :global(h1){
          margin-bottom: 10px;
          font-size: 2em;
        }
      }

      :global(.body){
/*        padding: 0 20px;*/
        margin-bottom: 20px;

        :global(input){
          width: 100%;
        }
      }

      :global(.functions){
        display: flex;
/*        margin: 20px;*/
        bottom: 0;
        justify-content: flex-end;
      }

      :global(.btn){
        margin-left: 10px;
        padding: 10px 20px;
      }
    }
  }
</style>