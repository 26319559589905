<script lang='ts'>
  import DieBlank from "../../dice-tray/die-blank.svelte";

  export var data;

  function getLevelText(index){
    switch(index){
      case 0:
        return '-2';
      case 1:
        return '-1';
      case 2:
        return '1';
      case 3:
        return '2';
      case 4:
        return '3';
      case 5:
        return '4';
      case 6:
        return '5';
      case 7:
        return '6';
      case 8:
        return '+1';
      case 9:
        return '+2';
    }
  }
  function getLevel(index){
    switch(index){
      case 0:
        return -2;
      case 1:
        return -1;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return 0;
      case 8:
        return 1;
      case 9:
        return 2;
    }
  }
</script>

<div class='container'>
  <h2>{data.title}</h2>

  <div class='dice'>
    {#each data.faces as face, index}
      <div>
        {getLevelText(index)}
        <DieBlank dieType={face} colour={data.colour} sizeMod={1} solid={true} level={getLevel(index)}></DieBlank>
      </div>
    {/each}
  </div>
</div>

<style lang='less'>
  .container{
    border: 2px solid var(--background-colour-light);
    padding: 5px;

    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
  }
  h2{
    text-align: left;
    text-transform: capitalize;
  }
  .dice{
    display: flex;
    grid-gap: 5px;

    margin: auto;
    width: fit-content;
  }
</style>